import { Col, Row, FaIcon, InputLabel, Separator } from '@web/atomic';
import { CheckboxField } from '@web/atomic/legacy/atm.checkbox';
import { TextField } from '@web/atomic/legacy/atm.text-field';
import { PhoneField } from '@web/atomic/legacy/atm.text-field/phone-field.component';
import { Form, Validators } from '@web/atomic/legacy/obj.form';
import * as React from 'react';
import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';
import { NativeHelper } from '@web/data/native.helper';

interface IContactFieldsProps {
  initialEmail: string;
  initialEmailEnabled: boolean;
  initialWhatsapp: string;
}

const canSendEmailId = 'canSendEmailId';

const ContactFields: React.FunctionComponent<IContactFieldsProps> = (props) => {
  const [email, setEmail] = React.useState(props.initialEmail);

  // On Native devices, there isn't the messenger flow, so it shouldn't be on that tooltip
  const ariaLabelWeb = ' quando não for possível fazê-lo via messenger.';
  const ariaLabelNative = '.';
  return (
    <>
      <Row>
        <Col xs={12}>
          <Form.Field
            name="email"
            initialValue={props.initialEmail}
            onValueChange={setEmail}
            validators={[Validators.EmailRegex('O e-mail digitado é inválido.')]}
          >
            <InputLabel
              role="tooltip"
              data-microtip-position="right"
              data-microtip-size="medium"
              aria-label={`Se você permitir, o e-mail será utilizado para entrarmos em contato com você${
                NativeHelper.isNative ? ariaLabelNative : ariaLabelWeb
              }`}
            >
              E-mail <FaIcon.Question />
            </InputLabel>
            <TextField disabled={NativeHelper.isNative} type={'email'} autoComplete="email" />
          </Form.Field>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          {email && (
            <Form.Field
              name="canSendEmailReminder"
              initialValue={isNullOrUndefined(props.initialEmailEnabled) || props.initialEmailEnabled ? [canSendEmailId] : []}
            >
              <Separator />
              <CheckboxField id={canSendEmailId}>Receber lembretes no e-mail acima</CheckboxField>
            </Form.Field>
          )}
          <Separator />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Separator />
          <Form.Field
            name="whatsapp"
            initialValue={props.initialWhatsapp}
            validators={[Validators.BrazilianPhoneRegex('O whatsapp digitado é inválido.')]}
          >
            <InputLabel
              role="tooltip"
              data-microtip-position="right"
              data-microtip-size="medium"
              aria-label={`Se você permitir, o whatsapp será utilizado para entrarmos em contato com você${
                NativeHelper.isNative ? ariaLabelNative : ariaLabelWeb
              }`}
            >
              Whatsapp <FaIcon.Question />
            </InputLabel>
            <PhoneField />
          </Form.Field>
          <Separator />
          <Separator />
        </Col>
      </Row>
    </>
  );
};

export default ContactFields;
