import { GatsbyButton } from '@components/atm.button/button.component';
import { ChatfuelBroadcastRequest } from '@global/utils/chat/chatfuel';
import { Gender, getGenderName, getGenderSuffix, SleepDiaryTechnique } from '@global/utils/domain/entities';
import { getBeforeSleepFactorsInfo, getDuringSleepFactorsInfo, getTechniquesInfo } from '@global/utils/domain/sleep-factor';
import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';
import ContactFields from '@lp-root/src/components/org.contact-fields/contact-fields.component';
import { UserQueryOutputUser } from '@lp-root/src/data/graphql/hasura/user.query.hasura';
import { ConfigurationTemplate } from '@lp-root/src/modules/configuration/components/configuration-template.component';
import { ScheduleConfigFormContainer } from '@lp-root/src/modules/configuration/components/schedule-config-form-container.component';
import { ScheduleConfigForm } from '@lp-root/src/modules/configuration/components/schedule-config-form.component';
import { SleepFactorsConfigurationFields } from '@lp-root/src/modules/configuration/components/sleep-factors-configuration-fields.component';
import { TechniqueConfigurationField } from '@lp-root/src/modules/configuration/components/technique-configuration-field.component';
import { Col, FaIcon, InputLabel, Row, Separator } from '@web/atomic';
import { StickButtonWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import { RadioField } from '@web/atomic/legacy/atm.radio/radio-field.component';
import { TextField } from '@web/atomic/legacy/atm.text-field';
import flashDispatcherService from '@web/atomic/legacy/obj.flash-wrapper/flash-dispatcher.service';
import { Form, FormData, Validators } from '@web/atomic/legacy/obj.form';
import { NativeHelper } from '@web/data/native.helper';
import { usePostUsingMessenger } from '@web/data/use-post-using-messenger.hook';
import { PostUrl } from '@web/data/vigilantes.datasource';
import { graphql } from 'gatsby';
import React, { useCallback } from 'react';
import { buildPostConfigRequest, ConfigurationRequestInput } from '../../data/request-utils/config.request';
import { PageProps } from '../../utils/local-types';

export type ConfigurationPageFormData = ConfigurationRequestInput;

const ConfiguracaoPage: React.FunctionComponent<PageProps> = (props) => {
  return <ConfigurationTemplate>{(user) => <ConfigFormRow user={user} />}</ConfigurationTemplate>;
};

// ------------------------------------------------------------------------------------------------------

interface ConfigFormProps {
  user: UserQueryOutputUser;
}

const ConfigFormRow: React.FunctionComponent<ConfigFormProps> = (props) => {
  const [submitUserConfig, { loading: updateUserLoading }] = useUpdateUserRequest();

  const beforeSleepFactors = getBeforeSleepFactorsInfo(props.user);
  const duringSleepFactors = getDuringSleepFactorsInfo(props.user);
  const techniques = getTechniquesInfo(props.user).filter((factor) => !isNullOrUndefined(factor.enable));

  const handleSubmit = useCallback(
    async (formData: FormData<ConfigurationPageFormData>) => {
      if (Object.keys(formData.error).length !== 0) return;
      const request = buildPostConfigRequest(
        props.user.id,
        formData.data,
        techniques.map((item) => item.id as SleepDiaryTechnique)
      );
      await submitUserConfig(request);
      /**
       * The code below is executed only on the native side to update the user attributes on the chat
       */
      const data: ChatfuelBroadcastRequest = {
        user: props.user.id,
        userAttributes: {
          'first name': request.first_name,
          'last name': request.last_name,
          gender: request.gender,
          'global-gender_suffix': getGenderSuffix(request.gender),
        },
      };
      NativeHelper.postMessage({ type: 'chatfuelBroadcast', data });
    },
    [props.user.id, submitUserConfig, techniques]
  );

  return (
    <Row>
      <Col xs={12} sm={12} md={7} lg={7}>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <Form.Field
                label={'Nome'}
                validators={[Validators.Required()]}
                name={'firstName' as keyof ConfigurationRequestInput}
                initialValue={props.user.first_name}
                key={props.user.first_name}
              >
                <TextField />
              </Form.Field>
              <Separator />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Field
                name={'lastName' as keyof ConfigurationRequestInput}
                initialValue={props.user.last_name}
                key={props.user.last_name}
              >
                <InputLabel
                  role="tooltip"
                  data-microtip-position="right"
                  data-microtip-size="medium"
                  aria-label="Esse campo pode ser útil para facilitar seu reconhecimento caso você esteja fazendo um acompanhamento com profissional da saúde."
                >
                  Sobrenome <FaIcon.Question />
                </InputLabel>
                <TextField />
              </Form.Field>
              <Separator />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Field
                initialValue={props.user.gender}
                name={'gender' as keyof ConfigurationRequestInput}
                validators={[Validators.Required()]}
              >
                <InputLabel>Gênero</InputLabel>
                <Separator />
                {Object.values(Gender).map((value) => (
                  <RadioField key={value} id={value}>
                    {getGenderName(value)}
                  </RadioField>
                ))}
                <Separator />
              </Form.Field>
            </Col>
          </Row>
          {props.user.email && (
            <ContactFields
              initialEmail={props.user.email}
              initialEmailEnabled={props.user.can_send_email_reminder}
              initialWhatsapp={props.user.phone}
            />
          )}
          <ScheduleConfigFormContainer userId={props.user.id}>
            {({
              initialSessionReminder,
              initialSessionReminderEnabled,
              initialSleepDiaryReminder,
              initialSleepDiaryReminderEnabled,
              initialSleepTrackerReminderEnabled,
              initialSleepTrackerReminder,
            }) => (
              <ScheduleConfigForm
                initialSessionReminder={initialSessionReminder}
                initialSessionReminderEnabled={initialSessionReminderEnabled}
                initialSleepDiaryReminder={initialSleepDiaryReminder}
                initialSleepDiaryReminderEnabled={initialSleepDiaryReminderEnabled}
                initialSleepTrackerReminderEnabled={initialSleepTrackerReminderEnabled}
                initialSleepTrackerReminder={initialSleepTrackerReminder}
              />
            )}
          </ScheduleConfigFormContainer>

          <SleepFactorsConfigurationFields beforeSleepFactors={beforeSleepFactors} duringSleepFactors={duringSleepFactors} />

          <TechniqueConfigurationField techniques={techniques} />

          <StickButtonWrapper>
            <Row mb center={'xs'}>
              <Col xs={12} sm={12} md={4} lg={4}>
                <GatsbyButton type="submit" kind="primary" loading={updateUserLoading} expanded>
                  Salvar configurações
                </GatsbyButton>
              </Col>
            </Row>
          </StickButtonWrapper>
          <Separator />
        </Form>
      </Col>
    </Row>
  );
};

const useUpdateUserRequest = () => {
  const handleSuccess = useCallback(() => {
    flashDispatcherService.dispatchMessage('Configuração alterada com sucesso!', 'success');
  }, []);

  return usePostUsingMessenger({ url: PostUrl.UpdateUser, onSuccess: handleSuccess });
};

export default ConfiguracaoPage;

export const query = graphql`
  query ConfiguracaoPage {
    site {
      ...SiteUrl
    }
  }
`;
